import styles from './Menu.module.scss';
import {useScreen} from "../../hooks/screen";
import {useEffect, useState} from "react";
import Flex from "../containers/Flex/Flex";
import Logo from "./Logo";
import Container from "../containers/Container/Container";
import Link from "../functional/Link";
import MenuButton from "./MenuButton";
import MenuItem from "./Menu/MenuItem";
import Awwwards from "../icons/Awwwards.svg";
import LinkedIn from "../icons/LinkedIn.svg";
import Facebook from "../icons/Facebook.svg";
import Instagram from "../icons/Instagram.svg";
import GitHub from "../icons/GitHub.svg";
import {useLayout} from "../../hooks/layout";

const Menu = () => {
    const {viewportWidth, viewportHeight, isMobile} = useScreen();

    const [svgPath, setSvgPath] = useState('');
    const [animationPercentage, setAnimationPercentage] = useState(0);
    const [shown, setShown] = useState(false);
    const [menuButtonOpen, setMenuButtonOpen] = useState(false);

    const {menuOpen, setMenuOpen} = useLayout()

    const menuItems = [
        {
            label: 'About',
            link: '/',
        },
        // {
        //     label: 'Work',
        //     link: 'work',
        // },
        // {
        //     label: 'Solutions',
        //     link: 'solutions',
        // },
        {
            label: 'Contact',
            link: 'contact',
        }
    ]

    const classNames = [styles.menu];

    if (menuOpen) {
        classNames.push(styles['menu--open']);
    }

    const animationEasing = (t: number) => {
        return (t === 1 ? 1 : 1 - Math.pow(2, -10 * t));
    };

    const animationDuration = 1500;

    const animate = (callback: (t: number) => void) => {
        const start = performance.now();
        const step = (timestamp: number) => {
            let progress = timestamp - start;
            if (progress > animationDuration) progress = animationDuration;
            callback(animationEasing(progress / animationDuration));
            if (progress < animationDuration) {
                requestAnimationFrame(step);
            }
        }
        requestAnimationFrame(step);
    }

    const setPath = (t: number) => {
        setAnimationPercentage(t);

        const bump_at_max = .5;
        const bump_percentage = 1 - (Math.abs(t - bump_at_max) / bump_at_max);
        const bump = (menuOpen ? 1 : -.5) * (Math.min(viewportWidth, viewportHeight) / 8 * bump_percentage);

        setSvgPath(`M 0,0
            H ${viewportWidth}
            v ${viewportHeight * t}
            c 0,0  
              -${viewportWidth / 4}, ${bump}  
              -${viewportWidth / 2}, ${bump}  
              -${viewportWidth / 4}, 0 
              -${viewportWidth / 2}, ${-bump} 
              -${viewportWidth / 2}, ${-bump}
             z`);
    }

    useEffect(() => {
        if (animationPercentage) {
            setShown(true);
        } else {
            setShown(false);
        }
    }, [animationPercentage]);

    useEffect(() => {
        if (menuOpen) {
            setTimeout(() => {
                setMenuButtonOpen(true);
            }, 100);
        } else {
            setMenuButtonOpen(false);
        }

        if (menuOpen && animationPercentage !== 1) {
            animate((t) => {
                setPath(t);
            });
        } else if (!menuOpen && animationPercentage !== 0) {
            animate((t) => {
                setPath(1 - t);
            });
        } else if (menuOpen && animationPercentage === 1) {
            setPath(1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuOpen, viewportWidth, viewportHeight]);

    if (shown) {
        classNames.push(styles['menu--shown']);
    }

    const onClick = (e: any) => {
        e.preventDefault();
        if (setMenuOpen)
            setMenuOpen(!menuOpen);
    }

    const stopPropagation = (e: any) => {
        e.stopPropagation();
    }

    return (
        <div className={classNames.join(' ')} onClick={onClick} data-scheme="invert">
            <svg className={styles['menu__svg']}
                 viewBox={`0 0 ${viewportWidth} ${viewportHeight}`} preserveAspectRatio="none">
                <clipPath id="clipPath">
                    <path d={svgPath}/>
                </clipPath>
            </svg>

            <div className={styles['menu__content']}>
                {shown && <>
                    <Container>
                        <Flex align="center" justify="between" splitWidth={true} noResponsive={true}>
                            <Link to="/"><Logo withText={!isMobile}/></Link>
                            <div>
                                <MenuButton
                                    open={menuButtonOpen}
                                ></MenuButton>
                            </div>
                            {!isMobile &&
                                <Link to="contact">Get in touch</Link>}
                        </Flex>
                    </Container>

                    <div className={styles['menu__items']}>
                        {menuItems.map((item, index) => (
                            <MenuItem label={item.label} link={item.link} key={index}/>
                        ))}
                    </div>

                    <div onClick={stopPropagation}>
                        <Container className={styles['menu__bottom']}>
                            <Link to={'https://www.instagram.com/berenger.dev'}
                                  className={styles['menu__bottom-link']}>
                                <Instagram/>
                            </Link>
                            <Link to={"https://www.facebook.com/berenger.be"}
                                  className={styles['menu__bottom-link']}>
                                <Facebook/>
                            </Link>
                            <Link to="https://www.linkedin.com/in/berengerstasse/"
                                  className={styles['menu__bottom-link']}>
                                <LinkedIn/>
                            </Link>
                            <Link to={"https://github.com/berenger-dev"}
                                  className={styles['menu__bottom-link']}>
                                <GitHub/>
                            </Link>
                            <Link to="https://www.awwwards.com/berenger.dev/"
                                  className={styles['menu__bottom-link'] + ' ' + styles['menu__bottom-link--awwwards']}>
                                <Awwwards/>
                            </Link>
                        </Container>
                    </div>
                </>
                }
            </div>
        </div>
    )
}

export default Menu;