import Lenis from '@studio-freight/lenis'

export const setupSmoothScroll = () => {
    init();
}

const init = () => {
    const lenis = new Lenis({
        //smoothTouch: true,
        smoothWheel: true
    })

    function raf(time: number) {
        lenis.raf(time)
        requestAnimationFrame(raf)
    }

    requestAnimationFrame(raf)
}