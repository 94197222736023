const Activities = () => {

    return (<></>);

    // return (
    //     <div>
    //         <Activity
    //             name="Web Development"
    //             description="I am a full-stack web and applications developer with a no-bullshit mind."
    //             image="https://picsum.photos/1000/1000"
    //         />
    //
    //         <Activity
    //             name="Web Development"
    //             description="I am a full-stack web and applications developer with a no-bullshit mind."
    //             image="https://picsum.photos/1000/1001"
    //         />
    //
    //         <Activity
    //             name="Web Development"
    //             description="I am a full-stack web and applications developer with a no-bullshit mind."
    //             image="https://picsum.photos/1000/1002"
    //         />
    //
    //         <Activity
    //             name="Web Development"
    //             description="I am a full-stack web and applications developer with a no-bullshit mind."
    //             image="https://picsum.photos/1000/1003"
    //         />
    //     </div>
    // )
}

export default Activities