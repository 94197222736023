import React, {useEffect} from "react";
import {Outlet, useParams} from "react-router-dom";
import {getBrowserLanguage} from "../helpers/browser";
import E404 from "../errors/E404";
import Header from "./Header";
import Footer from "./Footer";
import {useTranslation} from "react-i18next";
import Pointer from "../components/elements/Pointer";
import Menu from "../components/elements/Menu";
import {useRandomBeep} from "../hooks/random-beep";
import styles from "./Default.module.scss";
import {useLayout} from "../hooks/layout";
import {useScreen} from "../hooks/screen";

const Layout = () => {
    const {i18n} = useTranslation();
    const {lang} = useParams();
    const supported_languages = ["en"];

    let browser_language = getBrowserLanguage();
    browser_language = browser_language && supported_languages.includes(browser_language) ? browser_language : supported_languages[0];

    const default_language = browser_language ?? supported_languages[0];

    // const {playRandomSound} = useBackgroundSound();
    const {playRandomBeep} = useRandomBeep();

    const {scrolledPixels, documentHeight, viewportHeight} = useScreen();

    const {headerPosition} = useLayout();

    const {menuOpen, setMenuOpen, hasOverlay, hasTransitionMask} = useLayout();

    useEffect(() => {
        i18n.changeLanguage(lang).then(r => true);
    }, [i18n, lang]);
    const {hasOffsetTop} = useLayout();

    const playSound = () => {
        // playRandomSound();
        playRandomBeep();
    }

    if (!lang) {
        window.location.href = ("/" + default_language);
        return <></>;
    }

    if (!supported_languages.includes(lang)) {
        return <E404/>;
    }

    document.documentElement.lang = lang;

    const mainClassNames = [styles.main];

    if (menuOpen || hasOverlay) {
        mainClassNames.push(styles['main--menu-open']);
    }

    const headerClassNames = [styles.header];
    if (headerPosition) {
        headerClassNames.push(styles[`header--${headerPosition}`]);
    }

    if (hasOffsetTop) {
        headerClassNames.push(styles['header--has-offset-top']);
    }

    const layoutClassNames = [styles.layout];

    if (scrolledPixels > 1) {
        layoutClassNames.push(styles['layout--has-top-gradient']);
    }

    if (scrolledPixels && scrolledPixels < (documentHeight - viewportHeight - 200)) {
        layoutClassNames.push(styles['layout--has-bottom-gradient']);
    }

    const isContactPage = window.location.pathname.includes('contact');
    const showFooter = !isContactPage;

    return (
        <div onClick={playSound} className={layoutClassNames.join(' ')}>
            <header className={headerClassNames.join(' ')}>
                <Header menuOpen={menuOpen} setMenuOpen={setMenuOpen}/>
            </header>

            <main className={mainClassNames.join(' ')}>
                <Outlet/>
            </main>

            {showFooter &&
                <footer className={styles.footer}>
                    <Footer/>
                </footer>
            }

            {hasTransitionMask && <div className={styles['navigation-mask']}/>}

            <Menu/>

            <Pointer/>
        </div>
    )
}

export default Layout;