import React from 'react';
import ReactDOM from 'react-dom/client';
import {RouterProvider,} from "react-router-dom";
import router from "./router";
import i18n from "./translations";
import {I18nextProvider} from "react-i18next";
import './style/default.scss';
import {setupSmoothScroll} from "./helpers/scroll";

const root = ReactDOM.createRoot(
    document.getElementById('berenger-dev') as HTMLElement
);

setupSmoothScroll();

root.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18n}>
            <RouterProvider router={router}/>
        </I18nextProvider>
    </React.StrictMode>
);